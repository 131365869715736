/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./SellerInfo.vue?vue&type=template&id=4ab18df8&scoped=true&"
import script from "./SellerInfo.vue?vue&type=script&lang=js&"
export * from "./SellerInfo.vue?vue&type=script&lang=js&"
import style0 from "./SellerInfo.vue?vue&type=style&index=0&id=4ab18df8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab18df8",
  null
  
)

export default component.exports