//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("seller/getSellerSegments", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "Сегмент",
        show: true,
        name: "segment",
        width: 170,
        filter: "select",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "SellerInfo",
              params: {
                s_id: this.reportSettings.s_id,
                tab: "products"
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs,
                filters: JSON.stringify({
                  price: [item.segment_start, item.segment_end]
                })
              }
            },
            text: item.segment,
            target: "_blank"
          };
        }
      }, {
        title: `Выручка за ${days} дн`,
        show: true,
        name: "revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Продано за ${days} дн (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        ////title: `Выручка на товар`,
        title: `Средний чек`,
        show: true,
        name: "revenue_per_product",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }, {
        title: "Товаров",
        show: true,
        name: "products",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Товаров с продажами",
        show: true,
        name: "products_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Брендов",
        show: true,
        name: "brands",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Брендов с продажами",
        show: true,
        name: "brands_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};