//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("seller/getSellerBrands", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "Бренд",
        show: true,
        name: "brand",
        width: 440,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                b_id: item.b_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.brand,
            target: "_blank"
          };
        }
      }, {
        title: "Товаров",
        show: true,
        name: "products",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Товаров с продажами",
        show: true,
        name: "products_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Рейтинг товаров",
        show: true,
        name: "rating",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Отзывы",
        show: true,
        name: "reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Сред. отзывов на товар",
        show: true,
        name: "avg_reviews",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Продано за ${days} дн (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Выручка за ${days} дн`,
        show: true,
        name: "revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};